import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Header = styled.header`
    align-items: center;
    justify-content: center;
    min-height: auto;
    margin: 0 auto;
    .img{
        grid-area: 1/1;
        filter: brightness(0.8);
        z-index: -1;
        height: 400px;
        justify-self: center;
        width: 100%;
        ${({theme})=> theme.media.tablet}{
            height: 90vh;
        }
    } 
`

const ButtonZapisy = styled.button`
    margin:  10px auto;
    font-size: 1.3em;
    font-weight: lighter;
    color: whitesmoke;
    background-color: #B68A65;
    /* border: 10px solid white; */
    /* border-radius: 25px; */
    border: none;
    padding: 15px 15px;
    text-decoration: none;
    cursor: pointer;
    transition: 0.4s linear;
    /* min-width: 200px; */
    margin-bottom: 50px;
   
        
    &:hover{
        /* border: 10px solid #B68A65; */
         -webkit-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        -moz-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
    }
`

const ImageStyle = styled.div`
    display: grid;
    -webkit-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
    -moz-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
    box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);

    div{
        grid-area: 1/1;
        position: relative;
        /* place-items: center; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        
        h1{
            font-family: "Righteous", cursive;
            color: white;
            font-size: 2rem;
            font-weight: 800;
            text-shadow: 4px 4px 6px rgba(66, 68, 90, 1);
            line-height: 1.5em;
            /* color: #446971; */
            margin: 10px;
        }
        h2{
            font-size: 1.5em;
            font-weight: 300;
            color: white;
            line-height: normal;
            text-shadow: 4px 4px 6px rgba(66, 68, 90, 1);

        }
    }
    ${({theme})=> theme.media.tablet}{
        div{
            h1{
                font-size: 100px;
    
            }
            h2{
                font-size: 70px;
            }

        }
    }
`

const ButtonHero = styled.button`
    margin:  20px 20px;
    color: white;
    font-size: 0.8em;
    border: 1px solid white;
    background-color: #B68A65;
    font-weight: bold;

    padding: 10px 20px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    transition: 0.6s linear;
    min-width: 150px;


    &:hover{
         -webkit-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        -moz-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        color: white;
        background-color: #446971;
    }

    ${({theme})=> theme.media.tablet}{
        width: 250px;
        font-size: 1em;
        padding: 15px 20px;
        border: 3px solid white;
        color: white;
    }
`

const HeroZapisy = () => {
    return (
        <Header>
            <ImageStyle>
                <StaticImage
                    src="../images/hero-zapisy2.jpg"
                    alt="more ultra race"
                    // src="../images/ultramaraton_gravelowy_hero2.jpg"
                    // alt="more ultra race"
                    placeholder="blurred"
                    layout='constrained'
                    //   width={600}
                    height={600}     
                    transformOptions={{ fit: "cover", cropFocus: "center" }}
                    className='img'

                ></StaticImage>
                <div>
                    <AnimationOnScroll animateIn="animate__backInUp" duration={2} animateOnce={true}>
                    <h1>ZAPISY</h1>
                    {/* <h2>wśród malowniczych pejzaży Nadbużańskich wsi i rozlewisk</h2> */}
                    {/* <h2>Rozpocznij <strong>epicką Przygodę!</strong> <br/>Zapisz się na Ultra Race Dolina Bugu!</h2> */}
                    {/* <h2>zapisy od 23.02.2024</h2> */}
                    {/* <Link href="https://b4sportonline.pl/ultra_race_dolina_bugu/"><ButtonHero>jadę Dolinę!</ButtonHero></Link> */}
                    {/* <Link href="https://nowy-urdbg.bbtracker.pl"><ButtonHero>Śledź Gigante</ButtonHero></Link>
                    <Link href="https://nowy-urdbc.bbtracker.pl"><ButtonHero>Śledź Classico</ButtonHero></Link>  */}
                    </AnimationOnScroll>
                </div>
                
            </ImageStyle>
        </Header>
    );
}

export default HeroZapisy;
