import React from 'react'
import Layout from '../components/Layout'
// import Newslatter from '../components/Newslatter'
import { SEO } from "../components/Seo"
import Countdown from "../components/countdown"
import styled from 'styled-components'
import Pakiet from '../components/Pakiet'
import regulaminDolina from "../assets/regulaminDolinaBugu24.pdf"
import { Link } from 'gatsby'
import HeroZapisy from '../components/HeroZapisy'

import GaleriaSection from '../components/GaleriaSection'



const Title = styled.div`
    text-align: center;
    padding: 20px 5px;
    h1{
        padding: 20px 0;
        font-size: 1.8em;
        font-weight: 100;
        span{
            font-weight: 500;
            color: #446971;
        }
        ${({theme})=> theme.media.tablet}{
            font-size: 2em;
        }
    }

    h2{
        font-size: 1.2em;
        font-weight: 100;
        padding: 20px;
        margin: 2%;
        span{
            text-decoration: underline;
        }
    }

    a{
        color: black;
        font-size: 1.3em;
        :hover{
            color: #B68A65;

        }
    }
`

const ButtonZapisy = styled.button`
    margin:  10px auto;
    color: whitesmoke;
    background-color: #B68A65;
    font-size: 1.3rem;
    font-weight: lighter;
    /* border: 10px solid white; */
    /* border-radius: 25px; */
    border: none;
    padding: 15px 25px;
    text-decoration: none;
    cursor: pointer;
    transition: 0.4s linear;
    /* width: 200px; */
    margin-bottom: 50px;
   
        
    &:hover{
        /* border: 10px solid #B68A65; */
         -webkit-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        -moz-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
    }
`

const Zapisy = () => {
    return (
        <Layout>
            
        <HeroZapisy/>
        <Title>
            <h1>Dołącz do naszej niezwykłej wyprawy przez malownicze szlaki Doliny Bugu. Sprawdź, co przygotowaliśmy dla Ciebie i zarezerwuj swoje miejsce już teraz!</h1>
            {/* <h2>Zapisy startują <strong>23.02.2024</strong> o godzinie 20:00</h2> */}
            
            {/* <h1>Zapisz się na Ultramaraton Rowerowy <br></br><span>More Ultra Race Dolina Bugu</span></h1> */}
            {/* <h2>Jeśli chcesz przeżyć z nami rowerową przygodę, a obawiasz się, że przeoczysz zapisy. 
                Wystarczy, że zostawisz nam swojego maila, a poinformujemy Cię o rozpoczęciu zapisów.<br></br>
                <span>Dla osób z listy przewidujemy pierwszeństwo zapisów.</span>
            </h2> */}
            <Pakiet/>
            <Link href="https://b4sportonline.pl/ultra_race_dolina_bugu/">
                <ButtonZapisy>jadę Dolinę!</ButtonZapisy>
                </Link>
                <br></br>
                <a href={regulaminDolina} title="Regulamin Ultra Race Roztocze">Regulamin</a>
            <br></br><br></br>
            <Countdown/>
            {/* <Newslatter/> */}
            <GaleriaSection/>
            
            <br/>
            
            {/* <a href={regulaminDolina} title="Regulamin Ultra Race Dolina Bugu">Regulamin</a> */}
        </Title>
        {/* <Newslatter/> */}
    </Layout>
  )
}

export default Zapisy

export const Head = () => (
  <SEO title="Zapisy | Ultramaraton Rowerowy" description="Zapisz się na Ultramaraton Rowerowy Dolina Bugu."/>
)