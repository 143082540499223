import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components'

const Title = styled.h2`
  font-size: 2em;
  text-align: center;
  margin-top: 25px;
`

const Note = styled.p`
  text-align: center;
`

const ListItem = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`
 
const Item = styled.div`
  width: 100%;
  padding: 20px 10px;
  margin: 10px 10px;
  border: 8px solid #446971;
  -webkit-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
  -moz-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
  box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);

  h3{
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5em;
    margin-top: 10px;
    margin-bottom: 10px;
    span.promo{
      color: #B68A65;
      text-transform: lowercase;
      color: green;
      font-size: 1.8rem;
    }
    span.normal{
      padding-left: 10px;
      text-transform: lowercase;
      text-decoration-line: line-through;
      text-decoration-color: black;
      font-size: 1.1rem;   
    }
  }

  h5{
    font-size: 1.2em;
    text-align: right;
    text-decoration: underline;
  }
  
  ${({theme})=> theme.media.tablet}{
          max-width: 500px;     
          margin: 10px auto;  
      }
  
  div{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  ul{
    text-align: left;
  }

  .img {
    /* border: 10px solid whitesmoke; */
    padding: 20px;
    margin: auto auto;
    width: 120px;
    ${({theme})=> theme.media.tablet}{
      width: 200px;
     }
  }
  .skarp {
    /* border: 10px solid whitesmoke; */
    padding: 20px;
    margin: auto auto;
    width: 80px;

     ${({theme})=> theme.media.tablet}{
      width: 100px;
     }
  }
`

const ImagesStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`
const Pakiet = () => {
  return (
    <>
      <ListItem>
        <Item>
                <h3>Pakiet Startowy od<span className='promo'> 499PLN</span></h3>
                <h5>Na starcie</h5>
                <ul>
                    <li>Jedna ze specjalnie zaprojektowanych tras i w 100% przejechana przez Nas - km po km</li>
                    <li>System śledzenia online (wypożyczenie urządzenia GPS)</li>
                    <li>Pakiet zdjęć wykonany przez profesjonalny zespół</li>
                    <li>Przewiezienie depozytu ze startu na metę</li>
                    <li>Pit-stopy gdzie zregenerujesz siły, uzupełnisz brakujące kalorie - zaopatrzone w słodkie i słone przekąski, napoje, owoce</li>
                    <li>Pyszną niespodziankę na starcie</li>
                    <li>Pomiątkowa naszywka Ultra Race Dolina Bugu</li>
                    <li>Numer startowy</li>
                    <li>Ubezpieczenie NNW na czas trwania wydarzenia</li>
                </ul>
                <h5>Na mecie</h5>
                <ul>
                  <li>Pamiątkowe trofeum</li>
                  <li>Ciepły posiłek regeneracyjny</li>
                  <li>Wspólne afterparty z konkurami</li>
                </ul>
        </Item>
        
        
      </ListItem>
      <Note>*Opłata startowa ulega systematycznemu wzrostowi wraz z ilością dni pozostałą do rozpoczęcia zawodów. <br/>Do 10.04.2024 - 399 zł. Do 30.06.2024 - 469 zł. Do 02.08.2024 - 499 zł</Note>
    </>
  )
}

export default Pakiet;
